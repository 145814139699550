.page {
    padding: 5rem 0 0 0;
    margin-bottom: 3rem;
}

.product_card .logo {
    padding: 1rem;
    max-height: 90px;
}

.page .product_card .header {
    display: flex;
    justify-content: center;
}

.page .product_card {
    font-size: 20px;
    padding: 2rem 0;
    justify-content: center;
    background-color: white;
    color: black;
    box-shadow:
            inset 0 10px 15px rgba(255,255,255,0.3),
            inset 0 -10px 10px rgba(0,0,0,0.3),
            0 0 0 rgba(255,255,255,0.9);
}

.page .product_card .additional {
    font-size: 18px;
}

.page .product_card .additional .li_header {
    font-weight: bold;
}

.page .product_card .text .description {
    padding: 2rem 5rem;
    text-align: left;
    font-weight: bold;
    color: white;
    margin-right: 5rem;
    margin-bottom: 2rem;
}

.page .product_card .text #rx {
    background: #FF8C29FF;
}

.page .product_card .text #ario {
    background: #6c27c5;
}

.page .product_card .text #hr {
    background: #a61e4d;
}

.page .product_card .text #projects {
    background: #5aa21e;
}

.product_card ul {
    list-style: none inside;
    padding-left: 6rem;
}

.product_card li {
    padding: 0.5rem;
    border-radius: 5px;
    margin: 1rem 10rem 1rem 0;
    text-align: justify;
}

.product_card .link {
    display: flex;
    color: black;
    font-weight: bold;
    justify-content: right;
    padding: 0 5rem;
}

.page .product_card .additional #rx li {
    border-left: 10px solid #FF8C29FF;
}

.page .product_card .additional #ario li {
    border-left: 10px solid #6c27c5;
}

.page .product_card .additional #hr li {
    border-left: 10px solid #a61e4d;
}

.page .product_card .additional #projects li {
    border-left: 10px solid #5aa21e;
}

.page .product_card .link p {
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
}

.product_card .link #rx:hover {
    /*color: #FF8C29FF;*/
    color: white;
    background: #FF8C29FF;
}

.product_card .link #ario:hover {
    color: white;
    background: #6c27c5;

}

.product_card .link #hr:hover {
    color: white;
    background: #a61e4d;
}

.product_card .link #projects:hover {
    color: white;
    background: #5aa21e;
}