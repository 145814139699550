.login {
    justify-content: center;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5rem;
}

.login .header {
    padding: 5rem 0 1rem 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    display: block;
}

.login .error {
    padding: 0.5rem;
    font-weight: bold;
    color: crimson;
    background: #d593a0;
    text-align: center;
}

.login .form .error {
    padding: 0.5rem;
    font-weight: bold;
    color: crimson;
    background: rgba(220, 20, 60, 0.34);
    border-radius: 5px;
    text-align: center;
}
.form_div {
    display: flex;
    justify-content: center;
    background: white;
    color: black;
    /*box-shadow: inset 0 10px 15px rgba(255, 255, 255, 0.3),*/
    /*inset 0 -10px 10px rgba(0, 0, 0, 0.3),*/
    /*0 0 0 rgba(255, 255, 255, 0.9);*/
}

.login .form {
    display: block;
    padding: 1rem;
    margin-bottom: 1rem;
}

.login .form .div_form {
    text-align: right;
}

.login .form input {
    font-size: 20px;
    width: 300px;
    margin: 0.5rem;
}

.login .form button {
    width: 150px;
    font-size: 20px;
    padding: 0.5rem;
    /*border-radius: 10px;*/
    margin: 0.5rem;
    cursor: pointer;
    border: outset;
}

.login .form button:hover {
    background: #dadada;
}

/*.loginInput {*/
/*    margin: 10px;*/
/*    font-size: 30px;*/
/*    border-radius: 6px;*/
/*}*/

/*.loginInputButton {*/
/*    margin: 10px;*/
/*    padding: 10px;*/
/*    width: 200px;*/
/*    font-size: 30px;*/
/*    border-radius: 10px;*/
/*}*/