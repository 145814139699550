.home_header {
    padding: 5rem 0 1rem 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    display: block;
}

.home_text {
    font-weight: bold;
    font-size: 25px;
    display: block;
    text-align: center;
    padding: 1rem 0 7rem 0;
}

.home .product {
    background-color: white;
    color: black;
    box-shadow: inset 0 5px 10px rgba(255, 255, 255, 0.3),
    inset 0 -5px 10px rgba(0, 0, 0, 0.3),
    0 0 0 rgba(255, 255, 255, 0.9);
}

.home .header_product {
    text-align: center;
    justify-content: center;
    padding: -10px;
    font-size: 26px;
}

.home .directum_logo {
    max-height: 50px;
    cursor: pointer;
    vertical-align: middle;
    padding: 1rem;
}

.home .product_list_short {
    justify-content: center;
    /*flex-direction: column;*/
    /*display: grid;*/
    /*grid-template-columns: 40rem 40rem;*/
    display: flex;
}

.home .product_list_short img {
    max-height: 60px;
    padding: 3rem 1rem;
}

.home .review_card img {
    max-height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.home .review_card {
    margin: 1rem;
}

.home .review_card span{
    font-weight: bold;
    display: block;
    text-align: center;
}

.home .review_card .box {
    float: left;
    padding: 2rem;
    color: black;
    font-weight: bold;
    text-align: center;
}

.home .product_list_short .review_arrow {
    /*font-size: 25px;*/
    max-width: 100px;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .product_list_short .review_arrow img {
    transition: transform 2s;
}

@keyframes fadeInFromNone {
    0% {
        display: flex;
        opacity: 1;
    }
    20% {
        display: flex;
        opacity: 1;
    }
    100% {
        visibility: hidden;
        opacity: 0;
        cursor: default;
    }
}

.home .product_list_short .review_arrow img:hover {
    transform: scale(1.2);
}

.home .product_list_short span not .box {
    color: darkgray;
}

.home {
    margin-bottom: 5rem;
}

/*.home .product_list_short #rx, #hr {*/
/*    justify-content: right;*/
/*}*/

/*.home .product_list_short #hr .text {*/
/*    float: left;*/
/*}*/

/*.home .product_list_short #rx .text {*/
/*    float: left;*/
/*}*/

/*.home .product_list_short #projects, #ario {*/
/*    justify-content: left;*/
/*}*/

/*.home .product_list_short #projects .text {*/
/*    float: right;*/
/*}*/

/*.home .product_list_short #ario .text {*/
/*    float: right;*/
/*}*/

/*.home .product_list_short .card {*/
/*    display: flex;*/
/*    padding: 3rem 1rem;*/
/*}*/

/*.home .product_list_short .text {*/

/*}*/
