* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Segoe UI";
  background-color: #052242;
  color: white;
  height: 100%;
}

main {
  /*#3ba99c*/
  /*background-color: #0077b6;*/
  background-image: linear-gradient(#3ba99c, 30%, #0077b6);
  padding-bottom: 10px;
}

.footer {
  color: white;
  font-size: 15px;
  text-align: center;
  padding: 1rem;
  background-image: linear-gradient(rgba(0, 119, 182, 0.33), rgba(255,255,255,0));
}

.footer .contacts {
  justify-content: space-between;
  padding: 0 4rem;
  display: flex;
  text-align: right;
}