.navbar {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
}

.navbar ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.navbar a {
    color: midnightblue;
    text-decoration: none;
    padding: 0.5rem;
}

.navbar .other {
    background: linear-gradient(to right, royalblue, royalblue 50%, midnightblue 50%);
}

.navbar .other {
    transition: background-position 275ms ease;
}

.navbar .other {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
}

.navbar .other:hover {
    background-position: 0 100%;
}

.navbar .logo {
    padding: 10px;
}
