.feed {
    padding: 0 3rem;
}

.feed .error {
    font-size: 20px;
    background: rgba(255, 255, 255, 0.49);
    padding: 2rem;
    border-radius: 10px;
}

.feed .card {
    display: flex;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow:
            inset 0 5px 10px rgba(255,255,255,0.3),
            inset 0 -5px 5px rgba(0,0,0,0.3),
            0 0 0 rgba(255,255,255,0.9);
}

.feed .card .theme {
    font-size: 22px;
    font-weight: bold;
}

.feed .card .text {
    font-size: 20px;
}

.feed .header {
    padding: 5rem 0 1rem 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    display: block;
}

.feed .main {
    display: flex;
    justify-content: space-between;
}

.feed .main .main_div {
    width: 100%;
}

.ECMJournal {
    cursor: pointer;
    max-height: 600px;
    float: right;
    padding-left: 1rem;
}

.feed .feed_card {
    display: flex;
    padding: 1rem;
    font-size: 20px;
    color: black;
    background: white;
    box-shadow:
            inset 0 5px 10px rgba(255,255,255,0.3),
            inset 0 -5px 5px rgba(0,0,0,0.3),
            0 0 0 rgba(255,255,255,0.9);
    border-radius: 10px;
}

.feed .feed_card .theme {
    font-size: 22px;
    font-weight: bold;
    padding: 1rem 0;
}

.feed .feed_card .text {
    padding-bottom: 1rem;
}

.feed .feed_card .bold {
    font-weight: bold;
}

.feed .feed_card .feed {
    display: block;
}

.feed .block {
    padding: 0.3rem 0;
}