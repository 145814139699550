.about {
    background: white;
    box-shadow:
            inset 0 5px 10px rgba(255,255,255,0.3),
            inset 0 -5px 10px rgba(0,0,0,0.3),
            0 0 0 rgba(255,255,255,0.9);
    padding-bottom: 2rem;
    margin-bottom: 3rem;
}

.about_header {
    padding: 5rem 0 1rem 0;
    font-size: 35px;
    font-weight: bold;
    text-align: center;
    display: block;
}

.about .main {
    padding: 1rem 0;
    color: black;
}

.about .main .text {
    padding: 0.5rem 10rem;
    text-align: justify;
    font-size: 20px;
    text-indent: 3rem;
}

.about .main .text_start {
    padding: 2rem 10rem;
    font-size: 23px;
    text-align: left;
    font-weight: bold;
    background: #FF8C29FF;
    color: white;
    margin-right: 15rem;
    margin-bottom: 2rem;
}

.about .main .text_end {
    padding: 2rem 10rem;
    font-size: 23px;
    display: block;
    text-align: end;
    font-weight: bold;
    background: #FF8C29FF;
    color: white;
    margin-left: 15rem;
    margin-top: 2rem;
}

/*.about .additional {*/
/*    color: black;*/
/*}*/

/*.about .additional .header {*/
/*    font-weight: bold;*/
/*    padding: 1rem;*/
/*    font-size: 25px;*/
/*    display: grid;*/
/*    text-align: center;*/
/*    background: #FF8C29FF;*/
/*    color: white;*/
/*}*/

/*.about .additional .text {*/
/*    padding: 1rem 5rem 2rem;*/
/*    font-size: 20px;*/
/*    text-align: center;*/
/*    !*padding: 1rem;*!*/
/*}*/

/*.about {*/
/*    padding: 3rem 4rem;*/
/*    font-size: 17px;*/
/*}*/

/*.about .text {*/
/*    padding-top: 2rem;*/
/*    !*display: flex;*!*/
/*}*/

/*.about .main_text {*/
/*    font-size: 20px;*/
/*    !*justify-content: space-between;*!*/
/*    !*display: block;*!*/
/*    !*text-indent: 3rem;*!*/
/*    padding: 1rem;*/
/*    background-color: white;*/
/*    color: black;*/
/*    box-shadow:*/
/*            inset 0 5px 10px rgba(255,255,255,0.3),*/
/*            inset 0 -5px 10px rgba(0,0,0,0.3),*/
/*            0 0 0 rgba(255,255,255,0.9);*/
/*}*/

/*.about .text .contacts {*/
/*    width: 400px;*/
/*    margin-top: 1rem;*/
/*    vertical-align: baseline;*/
/*    padding: 1rem 3rem;*/
/*    font-size: 15px;*/
/*    background-color: #f18f01;*/
/*    border-radius: 10px;*/
/*    !*box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.29);*!*/
/*    box-shadow:*/
/*            inset 0 5px 10px rgba(255,255,255,0.3),*/
/*            inset 0 -5px 5px rgba(0,0,0,0.3),*/
/*            0 0 0 rgba(255,255,255,0.9);*/
/*}*/

/*.about .header {*/
/*    font-size: 22px;*/
/*    font-weight: bold;*/
/*}*/

/*.about .text .main {*/
/*    !*padding: 0 2rem;*!*/
/*}*/

/*.about .text .main .block .header {*/
/*    color: #f18f01;*/
/*}*/

/*.about .text .main .block {*/
/*    padding: 1rem 2rem;*/
/*    border-radius: 10px;*/
/*    background: white;*/
/*    margin-top: 1rem;*/
/*    color: black;*/
/*    box-shadow:*/
/*            inset 0 5px 10px rgba(255,255,255,0.3),*/
/*            inset 0 -5px 5px rgba(0,0,0,0.3),*/
/*            0 0 0 rgba(255,255,255,0.9);*/
/*}*/

/*.about .about_header {*/
/*    font-size: 30px;*/
/*    font-weight: bold;*/
/*    padding: 0.5rem;*/
/*    text-align: center;*/
/*}*/