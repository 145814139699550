.product_list {
    padding-top: 1rem;
}

.product_list a:active, a:hover, a {
    text-decoration: none;
    color: black;
}

.product_list .card{
    font-size: 20px;
    padding: 2rem;
    margin: 1rem 10rem;
    background-color: white;
    color: black;
    border-radius: 15px;
    box-shadow:
            inset 0 5px 10px rgba(255,255,255,0.3),
            inset 0 -5px 5px rgba(0,0,0,0.3),
            0 0 0 rgba(255,255,255,0.9);
}

.product_list img{
    max-height: 50px;
    align-self: center;
}